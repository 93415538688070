import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// 1) when seeding the database you'll have to uncomment this!
// import { seedDatabase } from '../seed';

const config = {
    apiKey: 'AIzaSyBvjaNenHXDznaLK_nBj6i-GajvI7QFjnM',
    authDomain: 'kestrel-19ecd.firebaseapp.com',
    databaseURL: 'https://kestrel-19ecd.firebaseio.com',
    projectId: 'kestrel-19ecd',
    storageBucket: 'kestrel-19ecd.appspot.com',
    messagingSenderId: '172095116246',
    appId: '1:172095116246:web:69b00cc17947395b070a77',
    measurementId: 'G-3JMXXEMMKB',
};

const firebase = Firebase.initializeApp(config);

// 2) when seeding the database you'll have to uncomment this!
// seedDatabase(firebase);

// 3) once you have populated the database (only run once!), re-comment this so you don't get duplicate data
export default firebase;
